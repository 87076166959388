import React from 'react'

export default function Lsb() {
  return (
        <div className="left-sidebar">
            <div className="sidebar-header d-flex align-items-center justify-content-between">
            <h2 className="email">Trevor Koertge</h2>
                <span className="designation">Data Engineer & Developer</span>
            </div>
            <img className="me" src="./assets/images/trevor-color.png" alt="Me"/>
            <h2 className="email">TrevorKoertge@gmail.com</h2>
            <h2 className="address">Based in Bossier City, LA</h2>
            <p className="copyright">&copy; 2022 Koertge. All Rights Reserved</p>
            <ul className="social-profile d-flex align-items-center flex-wrap justify-content-center">
                <li>
                    <a href="https://linkedin.com/in/tkoe/"><i className="lab la-linkedin"></i></a>
                </li>
                <li>
                    <a href="https://instagram.com/trevorkoertge"><i className="lab la-instagram"></i></a>
                </li>
                <li>
                    <a href="https://github.com/ticrom"><i className="lab la-github"></i></a>
                </li>
            </ul>
            <a href="#contact" className="theme-btn">
                <i className="las la-envelope"></i> Hire Me!
            </a>
        </div>
  )
}   
