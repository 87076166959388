import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Resume() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="resume-area page-section scroll-to-page" id="resume">
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-briefcase"></i> Resume
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>Education & <span>Experience</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2022 - Present</span>
                        <h2>Product Manager</h2>
                        <p>Complete Flight</p>
                        <h2>Business Process Analyst</h2>
                        <p>General Dynamics</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-left'>
                        <span className="date">2018 - 2022</span>
                        <h2>Continuous Quality Improvement Analyst</h2>
                        <p>Metro Aviation</p>
                        <h2>Lead Operational Control Specialist</h2>
                        <p>Metro Aviation</p>
                        <h2>Operational Control Specialist</h2>
                        <p>Metro Aviation</p>
                    </div>
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <span className="date">2015 - 2018</span>
                        <h2>Line Service Technician</h2>
                        <p>Ruston Aviation</p>
                    </div>
                </div>

            </div>
        </div>
        <br></br>
        <div className="custom-container">
            <div className="resume-content content-width">
                <div className="section-header">
                    <h1 className="scroll-animation" data-aos='fade-up'>Qualifications & <span>Certifications</span></h1>
                </div>

                <div className="resume-timeline">
                    <div className="item scroll-animation" data-aos='fade-right'>
                        <h2>Certified Scrum Master</h2>
                        <p>PSM1, 2022</p>
                        <h2>ITIL Foundations v4</h2>
                        <p>PeopleCert</p>
                        <h2>Private Pilot, Instrument Rated</h2>
                        <p>Federal Aviation Administration, 2017</p>
                        <h2>Commercial SUAS Pilot</h2>
                        <p>Federal Aviation Administration, 2019</p>
                        <h2>Data+ Certification</h2>
                        <p>Comp TIA, 2022</p>
                        <h2>A+ Certification</h2>
                        <p>Comp TIA, 2022</p>
                        <h2>Project+ Certification</h2>
                        <p>Comp TIA, 2022</p>
                        <h2>Level B2 Spanish Speaker</h2>
                        <p>CEF, 2022</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}
