import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

export default function Service() {
    useEffect(()=>{
        AOS.init({duration:1000})
    },[])
  return (
    <section className="services-area page-section scroll-to-page" id="services">
        <div className="custom-container">
            <div className="services-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-stream"></i> Services
                    </h4>
                    <h1 className="scroll-animation" data-aos='fade-up'>My <span>Specializations</span></h1>
                </div>

                <div className="services-items">
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-database"></i>
                        <h2>ETL Flow Design</h2>
                        <p>I employ powerful technologies to automatically gather, clean, store, and provide data in real time.</p>
                        <span className="projects">50 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-code"></i>
                        <h2>Development</h2>
                        <p>I build engaging front ends, frequently driven by my powerful data solutions.</p>
                        <span className="projects">126 Projects</span>
                    </div>
                    <div className="service-item scroll-animation" data-aos='fade-up'>
                        <i className="las la-chart-bar"></i>
                        <h2>Business Analysis</h2>
                        <p>Proven track record of improving business efficiency through vivid data visualizations, empowering stakeholders to make the right decision.</p>
                        <span className="projects">8 Projects</span>
                    </div>
                </div>    
            </div>
        </div>
    </section>
  )
}
