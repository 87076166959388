import React,{useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Lightbox from 'lightbox2';
import 'lightbox2/dist/css/lightbox.min.css';
// import 'lightbox2/dist/js/lightbox.js';

export default function Portfolio() {
    useEffect(() => {
        AOS.init({ duration: 1000 });
        Lightbox.option({
          resizeDuration: 200,
          fadeDuration: 600,
          imageFadeDuration: 600,
          wrapAround: true
        });
      }, []);
  return (
    <section className="portfolio-area page-section scroll-to-page" id="portfolio">
        <div className="custom-container">
            <div className="portfolio-content content-width">
                <div className="section-header">
                    <h4 className="subtitle scroll-animation" data-aos='fade-up'>
                        <i className="las la-grip-vertical"></i> portfolio
                    </h4>
                    <h1 className="scroll-animation">Featured <span>Projects</span></h1>
                </div>

                <div className="row portfolio-items">
                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                        <div className="portfolio-item portfolio-full">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/cloudcanopy.png" data-lightbox="example-1">
                                    <img src="../assets/images/cloudcanopy.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.mapbox.com">Mapbox</a>
                                    </li>
                                    <li>
                                        <a href="https://www.python.com">Python</a>
                                    </li>
                                    <li>
                                        <a href="https://www.sql.com">SQL</a>
                                    </li>
                                    <li>
                                        <a href="https://www.javascript.com">JavaScript</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://cloudcanopy.io">CloudCanopy.io - Live Aircraft/Marine Tracking + Weather Radar</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation" data-aos='fade-right'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/iss.png" data-lightbox="example-1">
                                    <img src="../assets/images/iss.png" alt="Portfolio"/>
                                </a>

                                <img src="../assets/images/iss.png" alt="Portfolio"/>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.wordpress.com">React</a>
                                    </li>
                                    <li>
                                        <a href="https://www.larevel.com">SQL</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://www.orbitwatcher.com">OrbitWatcher.com - International Space Station tracking site</a></h2>
                        </div>
                    </div>

                    <div className="col-md-6 scroll-animation"  data-aos='fade-left'>
                        <div className="portfolio-item portfolio-half">
                            <div className="portfolio-item-inner">
                                <a href="../assets/images/pox.png" data-lightbox="example-1">
                                    <img src="../assets/images/pox.png" alt="Portfolio"/>
                                </a>

                                <ul className="portfolio-categories">
                                    <li>
                                        <a href="https://www.python.com">Python</a>
                                    </li>
                                    <li>
                                        <a href="https://www.tableau.com">Tableau</a>
                                    </li>
                                </ul>
                            </div>
                            <h2><a href="https://www.poxtracker.net">Poxtracker - Monkeypox Analytics</a></h2>
                        </div>
                    </div>


                    <div className="col-md-12 scroll-animation" data-aos='fade-up'>
                    </div>
                </div>


            </div>
        </div>
    </section>
  )
}
